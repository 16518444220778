import type { $Fetch } from 'ofetch'

import ListingDataService from '@/services/listingDataService'

export default defineNuxtPlugin(({ $config }) => {
  const listingDataService = new ListingDataService({
    apiFetch: globalThis.$fetch as $Fetch,
    listingUrl: $config.public.listingApiUrl,
    xApplicationId: $config.public.environment,
    xApiKey: $config.public.listingApiKey,
    imsApiUrl: $config.public.imsApiUrl
  })

  return {
    provide: {
      listingDataService
    }
  }
})
