export interface SobreplanosUrls {
  mx: string
  co: string
}

export default defineNuxtPlugin(({ $config }) => {
  const sobreplanosUrls: SobreplanosUrls = {
    co: $config.public.sobreplanosUrlCo as string,
    mx: $config.public.sobreplanosUrlMx as string
  }

  return {
    provide: {
      sobreplanosUrls
    }
  }
})
