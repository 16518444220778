import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type { TrackEventParams } from '@/services/snowplowService/types'
export interface Traits {
  [key: string]: any
}

type SnowplowTracker = (params: TrackEventParams) => void
type TrackClicksHandler = (event: MouseEvent) => void
export default class UiClickTracker {
  trackClicksHandler:TrackClicksHandler
  snowplowTracker?: SnowplowTracker

  constructor ({ snowplowTracker } : { snowplowTracker?: SnowplowTracker }) {
    this.snowplowTracker = snowplowTracker

    this.trackClicksHandler = this.bindClickHandler()
    this.bindEvents()
  }

  bindEvents () {
    if (!this.snowplowTracker) return false

    document.addEventListener('click', this.trackClicksHandler)
  }

  devicePlatform (isMobile) {
    return isMobile ? 'WEB_MOBILE' : 'DESKTOP'
  }

  snowPlowTrackClicks (event: MouseEvent) {
    const target = event.target as HTMLElement

    const breakpoints = useBreakpoints(breakpointsTailwind)
    const isMobile = breakpoints.smaller('sm').value
    const sourcePlatform = this.devicePlatform(isMobile)

    const trait = {
      element_lh_id: target.dataset.lhId,
      device_platform: sourcePlatform,
      ui_details: {
        element_id: target.id,
        element_classes: Array.from(target.classList),
        element_tag_name: target.tagName
      }
    }

    const params: TrackEventParams = {
      name: 'ui_element_clicked',
      version: '1-0-0',
      schema: 'iglu:com.lahaus/ui_element_clicked/jsonschema/1-0-0',
      data: trait
    }

    this.snowplowTracker(params)
  }

  bindClickHandler (): TrackClicksHandler {
    return this.snowPlowTrackClicks.bind(this)
  }

  removeEvents () {
    document.removeEventListener('click', this.trackClicksHandler)
  }
}
