<script lang="ts" setup>
import colors from '@lahaus-roomie/roomie/config/colors.json'

const color = `repeating-linear-gradient(to right, ${colors.primary[800]} 0%, ${colors.brand[600]} 100%`
</script>

<template>
  <NuxtLoadingIndicator :color />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
