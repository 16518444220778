export default defineNuxtPlugin((nuxtApp) => {
  const logger = useLogger()

  nuxtApp.hook('app:chunkError', ({ error }) => {
    logger.error({
      message: error
    })
  })

  nuxtApp.hook('app:error', (error) => {
    logger.error({
      message: error
    })
  })
})
