export const simplify = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
}

export const slugify = (str: string) => {
  return simplify(str).replace(/ /g, '-')
}
