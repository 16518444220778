import type { Headers, Params, SnowplowServiceMethods, TrackEventBackParams } from './types'
import { getUrlData } from './utils'

export default class SnowplowService implements SnowplowServiceMethods {
  headers: Headers
  apiUrl: Params['apiUrl']
  apiFetch: Params['apiFetch']

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.apiUrl = params.apiUrl
    this.headers = {
      'X-Application-Id': 'buyer-web-front',
      'X-Api-Key': params.xApiKey,
      'Content-Type': 'application/json'
    }
  }

  async trackEventBack ({ name, version, domainUserid, userId, data }: TrackEventBackParams) {
    try {
      const url = `${this.apiUrl}/v1/event`

      await this.apiFetch(url, {
        method: 'POST',
        headers: { ...this.headers },
        body: {
          app_id: 'buyer-web-front',
          name,
          version,
          domain_userid: domainUserid,
          user_id: userId,
          data,
          ...getUrlData()
        }
      })
    } catch (error) {
      throw error.data
    }
  }
}
