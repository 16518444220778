import { v4 as uuidv4 } from 'uuid'

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp()

  const newSessionIdVal = uuidv4()
  const sessionIdCookie = useCookie<string>('session_id', {
    default: () => newSessionIdVal,
    maxAge: 60 * 60 * 4
  })

  if (process.server) {
    nuxtApp.$sessionId = nuxtApp.$sessionId || sessionIdCookie.value
    return
  }

  nuxtApp.$sessionId = sessionIdCookie.value
})
