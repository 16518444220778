import type { Headers, Params } from './types'

const ImsDataService = class {
  headers: Headers
  imsUrl: Params['imsUrl']
  apiFetch: Params['apiFetch']

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.imsUrl = params.imsUrl
    this.headers = {
      'X-Application-Id': params.xApplicationId,
      'x-api-key': params.xApiKey,
      'Content-Type': 'application/json'
    }
  }

  async getUnit (projectCode: string, unitCode: string) {
    try {
      const endpoint = `${this.imsUrl}/catalog_api/v1/projects/${projectCode}/properties/${unitCode}?version=2`
      const result = await this.apiFetch(endpoint, {
        method: 'GET',
        headers: {
          ...this.headers
        }
      })
      return result
    } catch (error: any) {
      throw new Error(error)
    }
  }
}

export default ImsDataService
