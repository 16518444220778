<script lang="ts" setup>
import { computed } from 'vue'

import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'

const { t } = useI18n()

interface Error {
  statusMessage?: string
  statusCode: number
}

const props = defineProps<{
  error: Error
}>()

const mappedErrors = [204, 400, 401, 404, 406, 500]
const errorCode = computed(() => {
  const code = props.error.statusCode
  return mappedErrors.includes(code) ? code : 500
})

const handleError = () => clearError({ redirect: '/' })
</script>

<script lang="ts">
export default {
  name: 'ErrorPage'
}
</script>

<template>
  <NuxtLayout name="error">
    <h1 class="font-semibold text-22 mb-10 text-center">
      {{ t(`error.${errorCode}_title`) }}
    </h1>

    <p class="text-18 mt-24 text-center">
      {{ t(`error.${errorCode}_subtitle`) }}
    </p>

    <figure class="mt-16">
      <img
        :src="`https://lahaus-roomie.imgix.net/roomie/statics/img/${error.statusCode}.png`"
        alt="fachada del apartamento 404, página no encontrada"
        class="mx-auto sm:w-1/2">
    </figure>

    <div class="text-center mt-24">
      <RoomieButton
        id="error-button-go-back-unexsiting-link"
        data-lh-id="error-button-go-back-unexsiting-link"
        :aria-label="t('error.go_back')"
        @click.prevent="handleError">
        {{ t('error.go_back') }}
      </RoomieButton>
    </div>
  </NuxtLayout>
</template>
