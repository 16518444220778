export interface whatsappNumbers {
  mx: string
  co: string
}

export default defineNuxtPlugin(({ $config }) => {
  const whatsappNumbers: whatsappNumbers = {
    co: $config.public.whatsappNumberCo as string,
    mx: $config.public.whatsappNumberMx as string
  }

  const whatsappBotNumbers: whatsappNumbers = {
    co: $config.public.whatsappBotNumberCo as string,
    mx: $config.public.whatsappBotNumberMx as string
  }

  return {
    provide: {
      whatsappNumbers,
      whatsappBotNumbers
    }
  }
})
