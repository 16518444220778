import { debounce } from '@lahaus-roomie/roomie/src/utils/debounce.js'
import type { TrackEventParams } from '@/services/snowplowService/types'

export interface Traits {
  [key: string]: any
}

type SnowplowTracker = (params: TrackEventParams) => void
type TrackScrollsHandler = (event: MouseEvent) => void
export default class UiScrollTracker {
  snowplowTracker?: SnowplowTracker
  trackScrollsHandler:TrackScrollsHandler

  constructor ({ snowplowTracker } : { snowplowTracker?: SnowplowTracker }) {
    this.snowplowTracker = snowplowTracker
    this.trackScrollsHandler = this.trackScrolls.bind(this)
  }

  bindEvents () {
    if (!this.snowplowTracker) return false

    // Temporary disable this track due the large amount of data and low usage on reports
    // document.addEventListener('scroll', this.trackScrollsHandler, true)
  }

  trackScrolls = debounce((event: Event) => {
    this.snowPlowTrackScroll(event)
  }, 100)

  trackerData (target: HTMLElement) {
    const topPosition = target.scrollTop
    const bottomPosition = topPosition + target.clientHeight
    const elementHeight = target.scrollHeight
    const percentageVertical = parseFloat((bottomPosition / elementHeight).toFixed(2))

    const leftPosition = target.scrollLeft
    const rightPosition = leftPosition + target.clientWidth
    const elementWidth = target.scrollWidth
    const percentageHorizontal = parseFloat((rightPosition / elementWidth).toFixed(2))

    const lhId = target.tagName === 'HTML' ? 'document-body' : target.dataset.lhId

    return {
      elementHeight,
      elementWidth,
      percentageVertical,
      percentageHorizontal,
      lhId
    }
  }

  snowPlowTrackScroll (event: Event) {
    const target = event.target instanceof Document ? event.target.documentElement : event.target as HTMLElement
    const {
      percentageVertical,
      percentageHorizontal,
      lhId
    } = this.trackerData(target)

    if (!lhId) return
    const trait = {
      element_lh_id: lhId,
      vertical_scroll: percentageVertical,
      horizontal_scroll: percentageHorizontal,
      ui_details: {
        element_id: target.id,
        element_classes: Array.from(target.classList),
        element_tag_name: target.tagName
      }
    }

    const params: TrackEventParams = {
      name: 'ui_element_scrolled',
      version: '2-0-0',
      schema: 'iglu:com.lahaus/ui_element_scrolled/jsonschema/2-0-0',
      data: trait
    }

    this.snowplowTracker(params)
  }

  removeEvents () {
    document.removeEventListener('scroll', this.trackScrollsHandler)
  }
}
