import { uniqBy } from 'lodash-es/'
import { slugify } from '@/utils/strings'
import type { ListingLocationResponse, LocationItem } from '@/services/listingDataService/types/locations'

export default class LocationsBuilder {
  public static build (results: ListingLocationResponse[]): LocationItem[] {
    const mappedLocations = results.map(({ location }) => {
      const countrySlug = slugify(location.country)
      const businessHubSlug = slugify(location.business_hub)
      const stateSlug = slugify(location.state)
      const citySlug = slugify(location.city)
      const zoneSlug = slugify(location.zone)
      const neighborhoodSlug = slugify(location.neighborhood)

      const key = [countrySlug, businessHubSlug, stateSlug, citySlug, zoneSlug, neighborhoodSlug].filter(Boolean).join('/')

      return {
        key,
        country: {
          name: location.country,
          value: countrySlug
        },
        businessHub: {
          code: location.business_hub_code,
          name: location.business_hub,
          value: businessHubSlug
        },
        state: {
          name: location.state,
          value: stateSlug
        },
        city: {
          name: location.city,
          value: citySlug
        },
        zone: {
          name: location.zone,
          value: zoneSlug
        },
        neighborhood: {
          name: location.neighborhood,
          value: neighborhoodSlug
        }
      }
    })

    return uniqBy(mappedLocations, ({ key }) => key)
  }
}
