export const getDomainUserId = () => {
  // Find all cookies that match the pattern
  const cookies = document.cookie.split('; ').filter(cookie => cookie.startsWith('_sp_id'))

  if (cookies.length === 0) {
    return '' // No _sp_id cookie found
  }

  // Extract and sort the cookies by the timestamp
  const parsedCookies = cookies.map((cookie) => {
    const parts = cookie.split('=')
    const valueParts = parts[1].split('.')
    return {
      name: parts[0],
      value: valueParts[0],
      timestamp: parseInt(valueParts[3] || '0', 10)
    }
  }).sort((a, b) => b.timestamp - a.timestamp)

  // Return the value of the most recent cookie
  return parsedCookies[0].value
}
