function sanitizeHtml (inputHtml: string): string {
  let cleanHtml = inputHtml.replace(/<script\b[^>]*>[\s\S]*?<\/script>/gi, '')

  cleanHtml = cleanHtml.replace(/(\s+on\w+=".*?")(\s|>)/gi, '$2')

  cleanHtml = cleanHtml.replace(/(href="javascript:.*?")(\s|>)/gi, 'href="#"$2')

  return cleanHtml
}

export default sanitizeHtml
