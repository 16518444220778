export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return

  const lhAuthTokenStatus = useCookie<string|null>('lh_auth_token_status')
  if (lhAuthTokenStatus.value === 'expired_token') {
    const authToken = useCookie<string|null>('lh_auth_token')
    try {
      const { $config } = useNuxtApp()

      const UniversalAuthServiceModule = await import('@/services/universalAuthService')
      const UniversalAuthService = UniversalAuthServiceModule.default
      const auth = new UniversalAuthService(
        $config.public.laHausAuthId,
        $config.public.environment
      )

      const token = await auth.getSessionToken()
      authToken.value = token

      lhAuthTokenStatus.value = null

      return await navigateTo(to, {
        external: true
      })
    } catch (error: any) {
      lhAuthTokenStatus.value = null
      authToken.value = null

      const logger = useLogger()
      logger.error({
        message: error.message,
        stack: error
      })
    }
  }
})
