import type { $Fetch } from 'ofetch'
import SnowplowService from '@/services/snowplowService'
import LeadEventService from '@/services/leadEventService'
import UiClickTracker from '@/services/ui-element-click-tracker'
import UiScrollTracker from '@/services/ui-element-scroll-tracker'
import type { TrackEventBackParams, TrackEventParams } from '@/services/snowplowService/types'
import type { TrackParams } from '@/services/leadEventService/types'
import { useUserStore } from '@/stores/userStore'
import { getDomainUserId } from '@/utils/getDomainUserId'

export default defineNuxtPlugin(({ $config }) => {
  const snowplowService = new SnowplowService({
    apiFetch: globalThis.$fetch as $Fetch,
    apiUrl: $config.public.b2CEventsApiUrl,
    xApiKey: $config.public.b2CEventsApiKey
  })

  const leadEventService = new LeadEventService({
    apiFetch: globalThis.$fetch as $Fetch,
    apiUrl: $config.public.leadEventApiUrl,
    xApiKey: $config.public.leadEventApiKey
  })

  const userStore = useUserStore()

  const trackLead = async ({ phone, listings, screen, screenCta, properties }: Omit<TrackParams, 'domainUserid'>) => {
    const domainUserid = getDomainUserId()

    const payload: TrackParams = {
      domainUserid,
      phone,
      screen,
      screenCta,
      listings,
      properties
    }

    if (userStore.isAuthenticated) payload.userId = userStore.user?.id
    await leadEventService.track(payload)
  }

  const trackEventBack = ({ data, name, version }: TrackEventBackParams) => {
    const domainUserid = getDomainUserId()

    const payload: TrackEventBackParams = {
      data,
      domainUserid,
      name,
      version
    }

    if (userStore.isAuthenticated) payload.userId = userStore.user?.id

    snowplowService.trackEventBack(payload)
  }

  const trackEvent = ({ name, version, data }: TrackEventParams) => {
    const selfDescribingEvent = {
      event: {
        schema: `iglu:com.lahaus/${name}/jsonschema/${version}`,
        data
      }
    }
    // @ts-ignore
    window.dataLayer?.push({
      event: name,
      ...data
    })

    snowplow('trackSelfDescribingEvent', selfDescribingEvent)
  }

  const trackPage = () => {
    if (typeof snowplow === 'undefined') return

    snowplow('trackPageView')
  }

  const nuxtApp = useNuxtApp()
  nuxtApp.hook('page:finish', () => {
    if (nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return

    trackPage()
  })

  const uiClickTrackerSnowplow = new UiClickTracker({ snowplowTracker: trackEvent })
  uiClickTrackerSnowplow.bindEvents()

  const uiScrollTracker = new UiScrollTracker({ snowplowTracker: trackEvent })
  uiScrollTracker.bindEvents()

  return {
    provide: {
      trackPage,
      trackEventBack,
      trackEvent,
      trackLead
    }
  }
})
