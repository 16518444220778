export const getUrlData = () => {
  const href = window.location.href
  const url = new URL(href)
  const urlData = {
    page_url: href,
    page_urlhost: url.host,
    page_urlpath: url.pathname,
    page_urlquery: url.search.slice(1)
  }

  return urlData
}
