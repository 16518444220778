// @ts-ignore
import { sanitizeUrl } from '@lahaus-roomie/roomie/src/utils/sanitizer.js'

import sanitizeHtml from '@/utils/htmlSanitizer'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('href', {
    created (el, binding) {
      el.href = sanitizeUrl(binding.value)
    },
    getSSRProps (binding) {
      return {
        href: sanitizeUrl(binding.value)
      }
    }
  })

  nuxtApp.vueApp.directive('sanitize', {
    created (el, binding) {
      el.innerHTML = sanitizeHtml(binding.value)
    },
    getSSRProps (binding) {
      return {
        innerHTML: sanitizeHtml(binding.value)
      }
    }
  })

  nuxtApp.vueApp.directive('clickoutside', {
    beforeMount (el, binding) {
      el.clickOutsideEvent = function (event: any) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event)
        }
      }
      document.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted (el) {
      document.removeEventListener('click', el.clickOutsideEvent)
    }
  })
})
