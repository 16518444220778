import type { Log, Logger } from './types'

const logger: Logger = () => {
  // @ts-ignore
  const nuxtApp = useNuxtApp()

  const warning: Log = ({ message, request, stack, callback }) => {
    if (nuxtApp.$logger) {
      nuxtApp.$logger.warning(message, request, stack, callback)
    } else {
      console.warn(message)
    }
  }

  const error: Log = ({ message, request, stack, callback }) => {
    if (nuxtApp.$logger) {
      nuxtApp.$logger.error(message, request, stack, callback)
    } else {
      console.error(message)
    }
  }

  const info: Log = ({ message, request, stack, callback }) => {
    if (nuxtApp.$logger) {
      nuxtApp.$logger.info(message, request, stack, callback)
    } else {
      console.info(message)
    }
  }

  return {
    warning,
    error,
    info
  }
}

export default logger
