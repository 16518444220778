import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45token_45validate_45global from "/app/middleware/00.tokenValidate.global.ts";
import _01_45token_45refresh_45global from "/app/middleware/01.tokenRefresh.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45token_45validate_45global,
  _01_45token_45refresh_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  "la-haus-auth": () => import("/app/middleware/la-haus-auth.ts")
}