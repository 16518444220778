import type { $Fetch } from 'ofetch'

import CdpService from '~/services/cdpService'

export default defineNuxtPlugin(({ $config }) => {
  const cdpDataService = new CdpService({
    apiFetch: globalThis.$fetch as $Fetch,
    cdpUrl: $config.public.cdpApiUrl as string,
    xApiKey: $config.public.cdpApiKey as string,
    xApplicationId: $config.public.environment
  })

  return {
    provide: {
      cdpDataService
    }
  }
})
