import type { Headers, Params, StrapiServiceMethods } from './types'

export default class StrapiService implements StrapiServiceMethods {
  headers: Headers
  apiUrl: Params['apiUrl']
  apiFetch: Params['apiFetch']

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.apiUrl = params.apiUrl
    this.headers = {
      'X-Application-Id': 'buyer-web-front',
      'Content-Type': 'application/json'
    }
  }

  getProjectRecomGroups (clusterSlug?: string) {
    let filtersQuery = ''

    if (clusterSlug) {
      filtersQuery = `?filters[cluster_slug][$eq]=${clusterSlug}`
    }

    const url = `${this.apiUrl}/api/project-recommendation-groups${filtersQuery}`

    return this.apiFetch(url, {
      method: 'GET',
      headers: { ...this.headers }
    })
  }

  getLegalPolicies (country?: string) {
    let filtersQuery = ''

    if (country) {
      filtersQuery = `?filters[country][$eq]=${country}`
    }

    const url = `${this.apiUrl}/api/legal-policies${filtersQuery}`

    return this.apiFetch(url, {
      method: 'GET',
      headers: { ...this.headers }
    })
  }
}
