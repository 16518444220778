import type { Headers, Params, LeadEventServiceMethods, TrackParams } from './types'

export default class LeadEventService implements LeadEventServiceMethods {
  headers: Headers
  apiUrl: Params['apiUrl']
  apiFetch: Params['apiFetch']

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.apiUrl = params.apiUrl
    this.headers = {
      'X-Application-Id': 'buyer-web-front',
      'X-Api-Key': params.xApiKey,
      'Content-Type': 'application/json'
    }
  }

  async track ({ domainUserid, userId, phone, screen, screenCta, listings, properties }: TrackParams) {
    try {
      const href = window.location.href
      const url = new URL(href)

      await this.apiFetch(this.apiUrl, {
        method: 'POST',
        headers: { ...this.headers },
        body: {
          domain_userid: domainUserid,
          user_id: userId,
          listings: listings.map(listingId => ({ id: listingId })),
          screen,
          screen_cta: screenCta,
          phone,
          properties,
          origin: {
            source: 'buyer-front-web',
            url: href,
            path: url.pathname,
            search: url.search.slice(1),
            screen,
            screen_cta: screenCta
          }
        }
      })
    } catch (error) {
      throw error.data
    }
  }
}
