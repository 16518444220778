import { ofetch } from 'ofetch'
import { v4 as uuidv4 } from 'uuid'

export default defineNuxtPlugin(() => {
  const logger = useLogger()
  const anonymousId = useCookie<number>('ajs_anonymous_id')
  const nuxtApp = useNuxtApp()

  globalThis.$fetch = ofetch.create({
    onRequest ({ options }) {
      const xRequestId = uuidv4()

      const context = {
        anonymous_id: anonymousId.value
      }

      const headers = {
        'x-request-id': xRequestId,
        'x-session-id': nuxtApp.$sessionId as string,
        'x-context': JSON.stringify(context)
      }

      options.headers = { ...options.headers, ...headers }
    },
    onResponseError ({ request, response }) {
      logger.error({
        message: `oFetch: ${response.statusText}`,
        request,
        stack: {
          request,
          status: response.status,
          body: response.body
        }
      })
    }
  })
})
