import { GrowthBook } from '@growthbook/growthbook'
import { getDomainUserId } from '@/utils/getDomainUserId'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $config, $trackEvent } = nuxtApp

  if (!$config.public.growthbookApiKeyFront) return {}

  const gb = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: $config.public.growthbookApiKeyFront,
    // This is set to true by default to debug the POC on production
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
      $trackEvent({
        name: 'experiment_viewed',
        version: '1-0-0',
        data: {
          experiment_id: experiment.key,
          variation_id: result.key
        }
      })
    }
  })

  await gb.loadFeatures()

  nuxtApp.hook('page:finish', () => {
    const anonymousId = getDomainUserId()
    const userId = useCookie<string>('ajs_user_id')

    gb.setAttributes({
      url: window.location.href,
      anonymousId,
      userId: userId.value
    })
  })

  return {
    provide: {
      growthbook: gb
    }
  }
})
