import {
  CO_LOCALE,
  MX_LOCALE
} from '@/utils/constants'

export default defineNuxtPlugin(({
  $config: {
    public: {
      gtmUrlCo,
      gtmUrlMx
    }
  },
  $i18n
}) => {
  const { locale } = $i18n as any

  const gtmUrl = {
    [CO_LOCALE]: gtmUrlCo,
    [MX_LOCALE]: gtmUrlMx
  }[locale.value as string]

  if (gtmUrl) {
    /* eslint-disable */
    // @ts-ignore
    (function(w,d,s,l,url){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= gtmUrl+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', gtmUrl);
    /* eslint-enable */
  }
})
