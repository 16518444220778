import type { $Fetch } from 'ofetch'

import ImsDataService from '@/services/imsDataService'

export default defineNuxtPlugin(({ $config }) => {
  const imsDataService = new ImsDataService({
    apiFetch: globalThis.$fetch as $Fetch,
    imsUrl: $config.public.imsApiUrl,
    xApplicationId: $config.public.environment,
    xApiKey: $config.public.imsApiKey
  })

  return {
    provide: {
      imsDataService
    }
  }
})
