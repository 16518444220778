import type { $Fetch } from 'ofetch'

import SobreplanosDataService from '@/services/sobreplanosDataService'

export default defineNuxtPlugin(({ $config }) => {
  const url = useRequestURL()
  const isCo = url.origin.includes($config.public.domainCo)
  const urlBase = isCo ? $config.public.sobreplanosUrlCo : $config.public.sobreplanosUrlMx

  const sobreplanosDataService = new SobreplanosDataService({
    apiFetch: globalThis.$fetch as $Fetch,
    urlBase,
    xApplicationId: $config.public.environment
  })

  return {
    provide: {
      sobreplanosDataService
    }
  }
})
